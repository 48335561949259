import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { ScrollbarContext } from '../context/scrollbar-context'

export const fragment = graphql`
  fragment PressMainContentFragment on WordPress_PressItem_Pressfields {
    content {
      mainContent
    }
  }
`

const SinglePress = ({ mainContent }) => {
  const [scrollbar] = useContext(ScrollbarContext)

  const scrollTop = () => {
    scrollbar.scrollTo(0, 100, 600)
  }

  return (
    <Outer>
      <Left>
        <Line />
        <Top onClick={() => scrollTop()}>
          <span>Top</span>
        </Top>
      </Left>
      <Right>
        <div dangerouslySetInnerHTML={{ __html: mainContent }} />
      </Right>
    </Outer>
  )
}

export default SinglePress

const Outer = styled.section`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 16.5%;
  z-index: 5;

  @media (max-width: 1260px) {
    padding: 0 12.5%;
  }

  @media (max-width: 650px) {
    padding: 0 30px 0;
  }

  h1 {
    font-size: 5rem;
    letter-spacing: -0.2rem;
    margin: 0 0 2.5rem;
    color: #fff;
  }

  h2 {
    font-size: 3rem;
    margin: 0 0 2.5rem;
    color: #fff;
  }

  h3 {
    font-size: 2.5rem;
    margin: 0 0 2.5rem;
    color: #fff;
  }

  h4 {
    font-size: 2rem;
    margin: 0 0 2.5rem;
    color: #fff;
  }

  p,
  li {
    font-family: ${props => props.theme.fonts.maison};
    color: #fff;
    font-size: 1.5rem;
    line-height: 2;

    @media (max-width: 650px) {
      font-size: 2rem;
    }
  }

  a {
    color: #fff;
    transition: color 0.8s ease;

    &:hover {
      color: ${props => props.theme.colours.orange};
    }
  }

  blockquote {
    border-left: solid 1px #fff;
    margin-left: 0;
    padding-left: 1.5rem;
    font-style: italic;
  }

  img {
    width: 100%;
    height: auto;
  }
`

const Left = styled.aside`
  position: relative;
  width: 20%;

  @media (max-width: 850px) {
    width: 100%;
    margin-bottom: 5rem;
  }
`

const Right = styled.aside`
  width: 70%;
  margin-bottom: 15rem;

  @media (max-width: 850px) {
    width: 100%;
  }

  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Line = styled.div`
  width: 6rem;
  height: 1px;
  background-color: #707070;
`

const Top = styled.figure`
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.8rem;
  height: 6.8rem;
  border: solid 2px ${props => props.theme.colours.orange};
  transform: translateY(-50%);
  cursor: pointer;

  @media (max-width: 850px) {
    display: none;
  }

  span {
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    font-size: 1.2rem;
    color: ${props => props.theme.colours.orange};
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    padding: 0.1rem 0 0 0.3rem;
  }
`
