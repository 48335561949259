import React, { useContext } from 'react'
import { graphql, navigate, Link } from 'gatsby'
import styled from 'styled-components'
import { TransitionContext } from '../context/transition-context'

import DownChevronSvg from '../../images/chevron-down.svg'

export const fragment = graphql`
  fragment PressIntroFragment on WordPress_PressItem_Pressfields {
    meta {
      author
      publishDate
      source
    }
  }
`

const SinglePress = ({ author, publishDate, source, title }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  return (
    <>
      <Outer>
        <h1>{title}</h1>
        <Small>{source}</Small>
        <Small>
          {publishDate}, {author}
        </Small>
        <Back>
          <Link
            to="/about"
            onClick={event => {
              event.preventDefault()
              setTransitionActive(true)
              setTimeout(() => {
                navigate(`/about#press`)
                setTransitionActive(false)
              }, 500)
            }}
          >
            <p>Back</p>
          </Link>
          <DownChevronSvg />
        </Back>
      </Outer>
      <BackgroundColour />
    </>
  )
}

export default SinglePress

const Outer = styled.section`
  position: relative;
  padding: 27rem 0 5rem 16.5%;
  z-index: 2;

  @media (max-width: 1260px) {
    padding: 15rem 12.5% 5rem;
  }

  @media (max-width: 650px) {
    padding: 25vh 30px 5rem;
  }

  h1 {
    font-size: 9rem;
    max-width: 65rem;
    letter-spacing: -0.2rem;
    margin: 0 0 2.5rem;

    @media (max-width: 650px) {
      font-size: 6rem;
    }
  }
`

const Small = styled.p`
  color: ${props => props.theme.colours.orange};
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  font-size: 1.2rem;

  @media (max-width: 800px) {
    font-size: 1.8rem;
  }
`

const BackgroundColour = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.colours.darkGrey};
  z-index: 1;
`

const Back = styled.button`
  position: absolute;
  top: 50%;
  left: 10rem;
  transform: translate(-9rem, -50%) rotate(90deg);
  transform-origin: bottom left;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  z-index: 10;

  @media (max-width: 650px) {
    position: absolute;
    top: 15vh;
    left: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    transform: translate(0, 0) rotate(0);
  }

  p {
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    line-height: 1.4;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0.8rem;

    @media (max-width: 650px) {
      font-size: 1.8rem;
    }
  }

  &:focus {
    outline: none;
  }

  a {
    text-decoration: none;
  }

  svg {
    position: relative;
    width: 1.5rem;
    left: -0.25rem;

    @media (max-width: 650px) {
      top: 0.3rem;
      transform-origin: center;
      transform: rotate(90deg);
      margin-right: 10px;
      width: 16px;
    }
  }
`
