import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Intro from '../components/single-press/intro'
import MainContent from '../components/single-press/main-content'
import Seo from '../components/seo'
import Press from '../components/press'

const SinglePress = ({ data, pageContext }) => (
  <Layout>
    <Seo pageTitle={pageContext.title} {...data.wordPress.pressItemBy.seo} />
    <Intro title={pageContext.title} {...data.wordPress.pressItemBy.pressFields.meta} />
    <MainContent {...data.wordPress.pressItemBy.pressFields.content} />
    <Press />
  </Layout>
)

export default SinglePress

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      pressItemBy(pressItemId: $wordpressId) {
        ...SeoFragmentSinglePress
        pressFields {
          ...PressIntroFragment
          ...PressMainContentFragment
        }
      }
    }
  }
`
